import * as firebase from 'firebase/app';
import { addDay } from './Tiime';
import { getLangFromComponent } from '../lang/logic';
import { ComponentLabel } from '../lang/dictionary';

const lang = getLangFromComponent(ComponentLabel.ConditionRepository)

const addMinutes = (d: Date, minutes: number): Date => new Date(d.getTime() + minutes * 60000);
export const meDeviceThen = (deviceId: string, action: () => void) => {
  if(deviceId.indexOf('2084') >= 0) {
    action();
  }
};

export const HirouRepository = {
  getTodayHirou: async (deviceId: string): Promise<{TPHours: number[], TPDay: number, averageOfTP: number}> => {
    let date = new Date();
    const start = new Date(date.setHours(0, 0, 0, 0));
    const end = new Date(date.setHours(23, 59, 59, 0));

    const todayDateInt = start.getFullYear() * 10000 + (start.getMonth() + 1) * 100 + start.getDate();

    const tenMinutesOfDay = [...Array(24 * 6)].map((_, i) => addMinutes(start, i * 10));

    const snapshot = await firebase.default.firestore().collection(deviceId).where('timestamp', '>', start)
            .where('timestamp', '<', end).orderBy('timestamp', 'desc').limit(10000).get();

    const summaryTPstoYesterdays = (await firebase.default.firestore().collection(`${deviceId}-summary`).where('keisokubi', '<', todayDateInt).get())
      .docs.map(doc => doc.data().TPDay).filter(x => x);

    const getChunkHoursTake6 = (array: number[]): number[][] => {
      const chunked = [];
      let children6 = [];
      for (let i = 0; i < array.length; i++) {
          children6.push(array[i]);

          if (i % 6 === 5) {
              chunked.push(children6);
              children6 = [];
          }
      }
      return chunked;
    };

    const getTPHours = (): number[] => {
      const TPAveragePer10Minutes = tenMinutesOfDay.map(time => {
          const time1 = time;
          const time2 = addMinutes(time, 10);

          const deviceDocs = snapshot.docs.filter(doc => {
              const endTimeStamp = doc.data().timestamp.toDate();

              return endTimeStamp >= time1 && endTimeStamp < time2;
          });

          const values = deviceDocs.length === 0 ? [] : deviceDocs.map(doc => doc.data().TP).filter(x => x !== undefined);
          const result = values.length === 0 ? null : values.reduce((acc, cur) => acc + cur) / values.length;

          return result;
      });

      return getChunkHoursTake6(TPAveragePer10Minutes)
        .map(chunk => chunk.length === 0 ? null : chunk.reduce((acc, cur) => acc + cur) / chunk.length);
    };

    const getTPDay = (tpHours: number[]): number | null => {
        const notNullTPHours = tpHours.filter(x => x !== null);
        return notNullTPHours.length === 0 ? null : notNullTPHours.reduce((acc, cur) => acc + cur) / notNullTPHours.length;
    };

    const TPHours = getTPHours();
    const TPDay = getTPDay(TPHours);

    const TPs = [...summaryTPstoYesterdays, TPDay].filter(x => x);
    const averageOfTP = TPs.length === 0 ? null : TPs.reduce((acc, cur) => acc + cur) / TPs.length;

    return {
      TPHours,
      TPDay,
      averageOfTP
    };
  },

  getHirouMessageFromNisshuseiHirou: (hiroudoMessages: string[]): string[] => {
    return hiroudoMessages.map((message, i) => i === 0 ? message : (message && hiroudoMessages[i - 1] ? lang.show('慢性疲労') : message));
  },
  
  getHirouMessage: (day: Date,
    sammeries: { keisokubi: number, time: any, TPHours: (number | undefined | null)[] }[],
    totals: { time: any, TPHoursTile25: number | null }[]): string => {

    let myActiveHours: number[] = [];

    const getDayTPAvg = () => {
      const time1 = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 0, 0);
      const time2 = addDay(time1, 1);
      const hours = sammeries.find(sammary => sammary.time.toDate().getTime() >= time1.getTime()
      && sammary.time.toDate().getTime() <= time2.getTime())?.TPHours;

      if(!hours || hours.length === 0) { return null; }

      const activeHours = hours.filter(hour => hour !== null && hour !== 0);
      myActiveHours = activeHours;

      return activeHours.length === 0 ? 0 : activeHours.reduce((a, b) => a + b, 0) / activeHours.length;
    };

    const getTPHoursTile25 = () => {
      // const dateInt = day.getFullYear() * 10000 + (day.getMonth() + 1) * 100 + day.getDate();
      const baseDay = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 0, 0);
      const time1 = new Date(baseDay.getFullYear(), baseDay.getMonth(), baseDay.getDate(), 0, 0, 0);
      const time2 = addDay(time1, 1);

      const total =  totals.find(t => t.time.toDate().getTime() >= time1.getTime() && t.time.toDate().getTime() < time2);

      return total === undefined ? null : (total.TPHoursTile25 ?? null);
    };

    const dayAvg = getDayTPAvg();
    const totalAvg = getTPHoursTile25();

    console.log({day, dayAvg, totalAvg, myActiveHours, sammeries, totals});

    if(!dayAvg || !totalAvg) { return '-'; }

    return dayAvg < totalAvg ? lang.show('前日の疲れ') : '-';
  },
};
